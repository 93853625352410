import React from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    List,
    ListActions,
    SelectInput,
    TextInput,
    ShowButton,
    TextField,
    ReferenceField
} from "react-admin";
import JobStateField, {JobState} from "../../components/JobStateField";
import {getEnumKeys} from "../../utils";

const filters = () => {
    const jobStates = getEnumKeys(JobState)
    return [
        <SelectInput label="State" source="state"  name="state" choices={jobStates.map(s => ({id: JobState[s], name: s}))}/>,
        <TextInput label="Owner" source="owner"  name="owner"/>,
        <TextInput label="Job name" source="name"  name="name"/>
    ];
}

export const JobsList = () => (
    <List actions={<ListActions/>} filters={filters()}>
        <Datagrid>
            <TextField source="id"/>
            <JobStateField source="state"/>
            <TextField source="name"/>
            <ReferenceField source="owner" reference="user_profiles"/>
            <DateField showTime={true} source="created_at"/>
            <DateField showTime={true} source="started_at"/>
            <DateField showTime={true} source="completed_at"/>
            <TextField source="template_id"/>
            <EditButton/>
            <ShowButton/>
        </Datagrid>
    </List>
);
