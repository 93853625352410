import {
    Datagrid, DateField,
    EditButton, NumberField, Pagination, ReferenceField, ReferenceManyField, Show,
    ShowProps,
    Tab,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React from "react";
import JsonField from "../../components/JsonField";

const JobTemplateShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    return <>
        <SimpleShowLayout {...props}>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="app_id" reference="apps"  link="show" />
            <ReferenceField source="schema_id" reference="schemas"  link="show" />
            <TextField source="partitioning_strategy"/>
            <TextField source="merge_strategy"/>
            <TextField source="data_type"/>
            <DateField showTime={true} source="updated_at"/>
            <DateField showTime={true} source="created_at"/>
        </SimpleShowLayout>

    </>
}

export const JobTemplateShow = (props: ShowProps) => {

    return <Show {...props} actions={<JobTemplateShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default JobTemplateShow
