import React, {useState} from 'react';
import {ActionDataProvider} from "../../hoc/ApiProvider";
import {
    Button,
    EditButton, Pagination, ReferenceManyField, Show, ShowProps, Tab, TabbedShowLayout, TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext,
    useRedirect,
    useResourceContext
} from "react-admin";
import {useNotify} from "ra-core";
import {FileCopy, StopCircle} from "@mui/icons-material";
import DataSourceField from "../../components/DataSourceField";
import JsonField from "../../components/JsonField";
import {TaskDataGrid} from "../Tasks";
import JobStateField, {JobState} from "../../components/JobStateField";
import OtLogField from "../../components/OtLogField";
import SelectInput from "@mui/material/Select/SelectInput";
import {getEnumKeys} from "../../utils";
import {Box, Chip, useTheme} from "@mui/material";
import {getTaskStateColor, getTaskStateThemeColor, TaskState, TaskStateChips} from "../../components/TaskStateField";
import CustomPagination from "../../components/CustomPagination";
import JobResultsField from "../../components/JobResultsField";

interface StatesMap {
    id: string;
    label: string;
    color: string;
}

const JobShowActions = (props: any) => {

    const dataProvider: ActionDataProvider = useDataProvider()
    const record = useRecordContext();
    const resource = useResourceContext()
    const redirect = useRedirect()
    const notify = useNotify()


    if (!record || !resource) {
        return <TopToolbar></TopToolbar>
    }

    const abortJob = (e: any) => {
        return dataProvider.action(resource.toString(), "abort", record.id.toString())
    }
    const copyJob = (e: any) => {
        return dataProvider.action(resource.toString(), "copy", record.id.toString()).then(resp => {
            redirect('show', resource.toString(), resp.data.id);
            notify("Job copied")
        })
    }

    return <TopToolbar>
        <EditButton/>
        {/* Add your custom actions */}
        <Button color="secondary" onClick={copyJob} label="Copy"><FileCopy/></Button>
        {record.state > 0 && record.state < 9 ?
            <Button color="primary" onClick={abortJob} label="Abort"><StopCircle/></Button> : null}
    </TopToolbar>
};

const ShowLayout = (props: any) => {
    const record = useRecordContext()
    if (!record) {
        return null
    }

    const [selectedState, setSelectedState] = useState(null)
    const filters: any = {}
    if (selectedState) {
        filters['state'] = selectedState
    }


    return <TabbedShowLayout {...props}>
        <Tab label="summary">
            <JobStateField source="state"/>
            <TextField label="Id" source="id"/>
            <TextField source="name"/>
            <TextField source="created_at"/>
            <TextField source="updated_at"/>
            <TextField source="started_at"/>
            {record.state === 8 ? <TextField source="completed_at"/> : null}
            <TextField source="state"/>
            <TextField source="owner"/>
            <TextField source="chunks_count"/>
            <TextField source="error_message"/>
        </Tab>
        <Tab label="data" path="data">
            <DataSourceField source="data_source"/>
            <DataSourceField source="data_destination"/>
        </Tab>
        <Tab label="parameters" path="params">
            <JsonField source="params"/>
        </Tab>
        <Tab label="tasks" path="tasks">
            <TaskStateChips onSelect={(v) => setSelectedState(v)} onDelete={(v) => setSelectedState(null)} selectedState={selectedState}/>
            <ReferenceManyField filter={filters} sort={{ field: 'chunk_number', order: 'ASC' }} reference="tasks" target="job_id" pagination={<Pagination/>}>
                <TaskDataGrid/>
                <CustomPagination hasNextPage={true} />
            </ReferenceManyField>
        </Tab>
        {record.state === 8 ? <Tab label="results" path="results">
            <JobResultsField source="results"/>
        </Tab> : null}
        <Tab label="Jobs API logs" path="job_log">
            <OtLogField service="customer-api" source="id"/>
        </Tab>
        <Tab label="Feeder logs" path="feeder_log">
            <OtLogField service="feeder" source="id"/>
        </Tab>
        <Tab label="Tasks API logs" path="tasks_log">
            <OtLogField service="tasks-api" source="id"/>
        </Tab>
    </TabbedShowLayout>
}

export const JobShow = (props: ShowProps) => {
    return <Show {...props} actions={<JobShowActions {...props}/>}>
        <ShowLayout/>
    </Show>
}

