import React from 'react';
import ReactDOM from 'react-dom/client';
import 'jsoneditor-react/es/editor.min.css';
import './index.scss';
import App from './App';
import env from "./env";
window["process"] = {env: env};


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>
);
