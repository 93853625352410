import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';
import {Circle} from "@mui/icons-material";
import {Box, Typography, useTheme} from "@mui/material";
import StateField from "./StateField";
import {Theme} from "@mui/material/styles";

export enum JobState {
    NEW = 0,
    UPLOADING = 1,
    PARTITIONING = 2,
    PARTITIONED = 3,
    QUEUED = 4,
    MERGING = 5,
    MERGED = 6,
    DOWNLOADING = 7,
    COMPLETE = 8,
    FAILED = 0xFF,
}

export const getLabelColor = (value: JobState, theme: Theme) => {
    switch (value) {
        case JobState.NEW:
            return  theme.palette.info.light
        case JobState.UPLOADING:
        case JobState.PARTITIONING:
        case JobState.PARTITIONED:
        case JobState.QUEUED:
            return  theme.palette.warning.main
        case JobState.MERGING:
        case JobState.MERGED:
            return  theme.palette.success.light
        case JobState.COMPLETE:
            return  theme.palette.success.main
        case JobState.FAILED:
            return  theme.palette.error.main
        default:
            return theme.palette.grey.A400
    }
}

const JobStateField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const label = (JobState[value]||'').toLowerCase()
    return <StateField label={label} color={getLabelColor(value, theme)}/>
}
export default JobStateField
