import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';
import {Circle} from "@mui/icons-material";
import {Box, Typography, useTheme} from "@mui/material";
import StateField from "./StateField";

enum RunnerState {
    UNKNOWN = 0,
    STOPPED = 1,
    RUNNING = 2,
    BUSY = 3,
    BACKGROUND = 4,
    CLOSED = 0xFE,
    ERROR = 0xFF
}

const JobStateField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const label = (RunnerState[value]||'').toLowerCase()
    let color = theme.palette.grey.A400
    switch (value) {
        case RunnerState.UNKNOWN:
            color = theme.palette.grey.A400
            break
        case RunnerState.STOPPED:
            color = theme.palette.warning.light
            break
        case RunnerState.BUSY:
        case RunnerState.RUNNING:
            color = theme.palette.success.main
            break
        case RunnerState.BACKGROUND:
        case RunnerState.CLOSED:
            color = theme.palette.warning.main
            break
        case RunnerState.ERROR:
            color = theme.palette.error.main
            break
    }
    return <StateField label={label} color={color}/>
}
export default JobStateField
