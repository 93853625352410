import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useDataProvider, useRecordContext} from 'react-admin';
import {ActionDataProvider} from "../hoc/ApiProvider";
import {useEffect, useState, Component} from "react";
import {Alert, Box, Button, Divider, Link} from "@mui/material";

import 'shaka-player/dist/controls.css';

//const shaka = require('shaka-player/dist/shaka-player.ui.js');


const resultsArrayRe = /\{(.*?)\}/

interface ResultLink {
    name: string
    link: string
}

interface VideoPlayerProps {
    name: string
    link: string
}

const DisplayError = ({err, ...props}: any) => {
    const [error, setError] = useState<string>(null)
    useEffect(() => {
        if (err.status && err.statusText) {
            const errTxt = `[${err.status} - ${err.statusText}]`
            if (err.json) {
                err.json().then((v: any) => setError(`${errTxt} ${JSON.stringify(v)}`)).catch((e: any) => setError(`${errTxt} ${e.toString()}`))
            } else {
                setError(errTxt)
            }
        } else {
            setError(JSON.stringify(err.message || err.toString()))
        }
    }, [err])
    return <Alert variant="filled" color="error">{error}</Alert>
}

const VideoPlayer = ({name, link, ...props}: any) => {
    /*const [playerState, setPlayerState] = useState(null);
    useEffect(() => {
        if (!shaka.Player.isBrowserSupported()) {
            return
        }
        shaka.polyfill.installAll()
        const video = document.createElement('video');
        video.src = "https://storage.googleapis.com/video-test-public/input/video_example.mp4" //link
        video.controls = true
        const player = new shaka.Player(video);
        player.configure('streaming.useNativeHlsOnSafari', false);
        const canvas = document.createElement('canvas');
        const lcevcDilConfig = {
            // optional settings here
        };
        player.attachCanvas(canvas);
        setPlayerState({player, canvas, video})
        console.log('set player', player, name)
    }, [])

    if (!shaka.Player.isBrowserSupported()) {
        return <Box>
            <Alert variant="filled" color="error">Browser not supported</Alert>
        </Box>
    }

    if (!playerState) {
        return <Box><Alert variant="outlined" color="info">Loading...</Alert> </Box>
    }
    console.log('playerState', playerState)
    return <Box>
        <h3>Play video: {name}</h3>
        <div ref={(elem) => {
            elem?.append(playerState.video)
        }}/>
    </Box>*/
    return <></>
}

class VideoPlayerP extends React.Component<VideoPlayerProps> {
    private videoComponent: React.RefObject<unknown>;

    constructor(props: VideoPlayerProps) {
        super(props);

        this.videoComponent = React.createRef();

        this.onErrorEvent = this.onErrorEvent.bind(this);

        this.onError = this.onError.bind(this);
    }

    onErrorEvent(event: any) {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }

    onError(error: any) {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }

    componentDidMount() {
        /*console.log('props', this.props.name)

        const video = this.videoComponent.current;

        var player = new shaka.Player(video);

        // Listen for error events.
        player.addEventListener('error', this.onErrorEvent);
        player.configure('streaming.useNativeHlsOnSafari', false);

        const canvas = document.createElement('canvas');
        const lcevcDilConfig = {
            // optional settings here
        };
        player.attachCanvas(canvas);

        const dilPlayer = shaka.lcevc.Dil(video, canvas, lcevcDilConfig)
        console.log('dilPlayer', dilPlayer)

        // Try to load a manifest.
        // This is an asynchronous process.
        player.load(this.props.link).then(function () {
            // This runs if the asynchronous load is successful.
            console.log('The video has now been loaded!');
        }).catch(this.onError);  // onError is executed if the asynchronous load fails.*/

    }

    render() {
        return <></>;
        //return <video width="640" ref={this.videoComponent} poster="//shaka-player-demo.appspot.com/assets/poster.jpg" controls/>
    }

}

const VideoList = ({links, onSelect, ...props}: { links: ResultLink[], onSelect: (r: ResultLink) => void }) => {
    return <Box display="flex" flexDirection="column">
        {links.map(l =>
            <Box key={`video-list-box-${l.name}`} display="flex" flexDirection="row" alignItems="center">
                <h5>{l.name}</h5>
                <Box mx={1}><Divider orientation="vertical" flexItem/></Box>
                <Button color="primary" onClick={() => onSelect(l)}>Play {l.name}</Button>
                <Box mx={1}><Divider orientation="vertical" flexItem/></Box>
                <Link variant="button" key={`link-${l.name}`} href={l.link} target="_blank">Open {l.name}</Link>
            </Box>
        )}
    </Box>
}

const JobResultsField = (props: PublicFieldProps) => {
    const {source} = props;
    const record = useRecordContext(props);
    const dataProvider: ActionDataProvider = useDataProvider()
    const value = get(record, source)
    const m = resultsArrayRe.exec(value || "")
    const recordId = record.id.toString()
    const [error, setError] = useState<string>(null)
    const [selectedVideo, setSelectedVideo] = useState<ResultLink>(null)
    const [resultLinks, setResultLinks] = useState<ResultLink[]>([])

    useEffect(() => {
        dataProvider.action('jobs', 'results', recordId).then(resp => {
            if (resp.data?.links && resp.data.links.length > 0) {
                setResultLinks(resp.data.links)
            } else {
                setResultLinks([])
            }
        }).catch(err => {
            setError(err)
        })
    }, [recordId])

    if (!m || m.length < 2) {
        return <pre>{value}</pre>;
    }
    const resultNames = m[1].replace(/(?<file>[A-Za-z0-9_-]+\.[A-Za-z0-9]{3,4})(,|$)/g, (...re: any[]) => {
        return `${re[re.length - 1].file}\n`
    })


    if (error) {
        return <DisplayError err={error}/>
    }
    //<Link key={`link-${l.name}`} href={l.link} target="_blank">{l.name}</Link>
    return <Box display="flex" flexDirection="column">
        <VideoList links={resultLinks} onSelect={setSelectedVideo}/>
        {selectedVideo ? <VideoPlayerP name={selectedVideo.name} link={selectedVideo.link}/> : null}
    </Box>;
}
export default JobResultsField
