import React from 'react';

import {Datagrid, DateField, EditButton, List, ListActions, Login,
    LoginFormClasses, LoginFormProps, ShowButton, TextField, TextInput} from 'react-admin'
import JobStateField from "../components/JobStateField";
import {Form, required, useLogin, useNotify, useSafeSetState, useTranslate} from "ra-core";
import {CardContent, CircularProgress, Button, withStyles, styled, Typography, CardHeader, Box} from '@mui/material';
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";

interface FormData {
    email: string;
}
interface LoginRequest {
    email: string;
    nonce: string;
}
interface OtpRequest {
    code: string;
}

export const LoginForm = (props: LoginFormProps) => {
    const { redirectTo, className } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const [loginRequest, setLoginRequest] = useSafeSetState<LoginRequest>(null);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const auth = useAuthContext()

    const errorHandler = (error: any) => {
        setLoading(false);
        notify(
            typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
            {
                type: 'warning',
                messageArgs: {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                                ? error.message
                                : undefined,
                },
            }
        );
    }

    const requireOtpCode = (values: FormData) => {
        setLoading(true);
        return auth.requestSignInCode(values.email).then(resp => {
            setLoading(false)
            setLoginRequest({email: values.email, nonce: resp.nonce})
        }).catch(errorHandler);
    };

    const loginWithOtp = (values: OtpRequest) => {
        setLoading(true);
        const loginData = {
            ...loginRequest,
            code: values.code
        }
        login(loginData, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(errorHandler);
    }
    //TODO: translate('ra.auth.email')

    const submitButton = <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth
        className={LoginFormClasses.button}>
        {
            loading ?
                <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} /> :
                (loginRequest ? translate('ra.auth.sign_in') : 'Next')
        }
    </Button>


    return <>
        { loginRequest ?
            <Form onSubmit={loginWithOtp} mode="onChange"  noValidate className={className}>
                <CardContent className={LoginFormClasses.content}>
                    <Typography variant="subtitle1" textAlign="center">
                        Insert the OTP code received at <strong>{loginRequest.email}</strong>
                    </Typography>
                    <TextInput autoFocus key="code-input" source="code" name="code"  label="OTP" type="text" validate={required()} fullWidth/>
                    {submitButton}
                </CardContent>
            </Form> :
            <Form onSubmit={requireOtpCode} mode="onChange"  noValidate className={className}>
                <CardContent className={LoginFormClasses.content}>
                    <Typography variant="h5" textAlign="center">ByteNite login</Typography>
                    <TextInput autoFocus key="email-input" source="email" name="email" label="Email" validate={required()} fullWidth />
                    {submitButton}
                </CardContent>
            </Form>
        }

    </>
};

const StyledLoginForm = styled(LoginForm)(({ theme }) => {
    return {
        [`& .${LoginFormClasses.content}`]: {
            width: 300,
        },
        [`& .${LoginFormClasses.button}`]: {
            marginTop: theme.spacing(2),
        },
        [`& .${LoginFormClasses.icon}`]: {
            margin: theme.spacing(0.3),
        },
    }
});

export const LoginPage = (props:any) => {

    return <Login>
        <StyledLoginForm/>
    </Login>
}

export default LoginPage