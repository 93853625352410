import React from "react";
import {AuthProvider, UserIdentity} from 'react-admin';
import {AuthContextInterface, User} from "@bytenite/auth/src/hoc/Auth/context";

interface LoginParams {
    email: string;
    nonce: string;
    code: string;
}

const authProvider = (auth: AuthContextInterface<User>): AuthProvider => {

    return {
        async checkAuth(params: any): Promise<void> {
            if (auth.isAuthenticated()) {
                return Promise.resolve()
            }
            const loginUrl = await auth.getLoginUrl()
            window.location.href = loginUrl
            return Promise.reject(undefined);
        }, checkError(error: any): Promise<void> {
            return Promise.resolve(undefined);
        }, getIdentity(): Promise<UserIdentity> {
            const userInfo = auth.getUserInfo()
            if (!userInfo) {
                return Promise.reject()
            }
            return Promise.resolve({
                id: userInfo.email,
                fullName : userInfo.displayName
            })
        }, getPermissions(params: any): Promise<any> {
            return Promise.resolve(undefined);
        }, async login(params: any): Promise<any> {
            const loginParams = params as LoginParams
            const loginUrl = await auth.getLoginUrl()
            window.location.href = loginUrl
            return new Promise((resolve, reject) => {
            });
        }, logout(params: any): Promise<void | false | string> {
            return new Promise(resolve => {
                auth.signOut()
                resolve()
            })
        }
    }
}

export default authProvider
