import {Datagrid, DateField, List, ListActions, ShowButton, TextField} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";

export const AppList = () => (
    <List actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="version"/>
            <TextField source="platform"/>
            <TextField source="owner"/>
            <DateField showTime={true} source="created_at"/>
            <ShowButton/>
        </Datagrid>
    </List>
);
