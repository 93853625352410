import {ActionDataProvider} from "../../hoc/ApiProvider";
import {Button, useDataProvider, useRecordContext, useRedirect, useRefresh, useResourceContext} from "react-admin";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import React from "react";

export const DisconnectButton = (props: any) => {
    const dataProvider: ActionDataProvider = useDataProvider()
    const record = useRecordContext();
    const resource = useResourceContext()
    const refresh = useRefresh();

    const disconnectDevice = () => {
        return dataProvider.action(resource, "disconnect", record.id.toString()).then(resp => refresh())
    }

    return <Button color="primary"
                   onClick={() => disconnectDevice()}
                   label="disconnect">
        <LinkOffIcon/>
    </Button>
}
