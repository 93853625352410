import {Routes, Route, useNavigate, BrowserRouter, HashRouter} from "react-router-dom";
import {byteniteTheme} from "@bytenite/components/src/themes/bytenite-theme/byteniteTheme";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React, {useEffect, useState} from "react";
import env from "./env";
import Admin from "./admin";
import AuthProvider from "@bytenite/auth/src/hoc/Auth/Provider";
import {ApiService as AuthApiService} from "@bytenite/auth/src/services/ApiService";
import ModalProvider from "@bytenite/components/src/hoc/Modals/Provider";
import {OAuth2Client} from "@badgateway/oauth2-client";

const serverConfigFactory = (conf) => {
    return {
        ...conf,
        basePath: `${conf.PROTOCOL}://${conf.SERVER_URL}/${conf.API_VERSION||'v1'}`, //
        redirectUrl: `${window.location.origin}/callback` //
    }
}
const serverConfig = serverConfigFactory(env)

export function AppContent () {
    const [envLoading, setEnvLoading] = useState(true)

    useEffect(() => {
        setEnvLoading(false)
        /*fetch('/.env').then(resp => {
            return resp.json().then(data => {
                Object.assign(serverConfig, serverConfigFactory(data))
            })
        }).finally(() => {setEnvLoading(false)})*/
    }, [])

    if (envLoading) {
        return <></>
    }

    const authApiService = new AuthApiService({...serverConfig})

    const oauthClient = new OAuth2Client({
        // The base URI of your OAuth2 server
        server: serverConfig.AUTH_SERVER,
        fetch: fetch.bind(window),
        discoveryEndpoint: '/.well-known/openid-configuration',
        authorizationEndpoint: '/oauth2/auth',
        // OAuth2 client id
        clientId: 'bytenite-app',
        //authenticationMethod: 'client_secret_post'
    });


    return (
        <BrowserRouter>
            <AuthProvider apiService={authApiService} oauthClient={oauthClient} redirectUri={serverConfig.redirectUrl}>
                <Admin serverUrl={serverConfig.SERVER_URL? `${serverConfig.PROTOCOL}://${serverConfig.SERVER_URL}` : ''} loginPage={null}/>
            </AuthProvider>
        </BrowserRouter>
    );
}

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <CssBaseline/>
            <AppContent/>
        </StyledEngineProvider>
    );
}

export default App;

//

