import {
    Button, Datagrid, DateField,
    EditButton, List, NumberField, Pagination, PaginationProps, ReferenceField, ReferenceManyField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider, useListPaginationContext,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React, {FC, useState} from "react";
import {CommonFilterCondition} from "../../client";
import CouponStateField from "../../components/CouponStateField";

const CouponShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    const [selectedState, setSelectedState] = useState(null)
    const filters: any = {op:CommonFilterCondition.ARRAYCONTAINS}
    if (selectedState) {
        filters['state'] = selectedState
    }

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id"/>
            <TextField source="code"/>
            <CouponStateField source="state"/>
            <DateField showTime={true} source="created_at" />
            <DateField showTime={true} source="updated_at" />
            <ReferenceField label="User" source="user_id" reference="user_profiles"/>
            <NumberField source="amount" />
            <NumberField source="quantity" />
            <DateField showTime={true} source="redeemed_at" />
        </Tab>
    </TabbedShowLayout>
    </>
}

export const CouponShow = (props: ShowProps) => {

    return <Show {...props} actions={<CouponShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default CouponShow
