import {
    Datagrid, DateField,
    EditButton, NumberField, Pagination, ReferenceField, ReferenceManyField, Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React from "react";
import {CommonFilterCondition} from "../../client";
import TaskStateField from "../../components/TaskStateField";

const AppShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id"/>
            <TextField source="version"/>
            <TextField source="owner"/>
            <TextField source="platform"/>
            <TextField source="hash"/>
        </Tab>
        <Tab label="code">
            <TextField source="manufacturer_device_id"/>
        </Tab>
        <Tab label="job templates">
            <ReferenceManyField pagination={<Pagination />} label={false} reference="job_templates" target="app_id">
                <Datagrid>
                    <TextField source="id"/>
                    <DateField source="created_at" showTime={true}/>
                    <TextField source="name"/>
                    <TextField source="data_type"/>
                    <TextField source="partitioning_strategy"/>
                    <ReferenceField source="schema_id" reference="schemas" />
                </Datagrid>
            </ReferenceManyField>
        </Tab>
    </TabbedShowLayout>
    </>
}

export const AppShow = (props: ShowProps) => {

    return <Show {...props} actions={<AppShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default AppShow
