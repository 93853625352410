import {
    Datagrid, DateField,
    EditButton, NumberField, Pagination, ReferenceField, ReferenceManyField, Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React from "react";
import {CommonFilterCondition} from "../../client";
import TaskStateField from "../../components/TaskStateField";
import {JobPresetCreate} from "./index";
import JsonField from "../../components/JsonField";

const JobPresetShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <TextField source="category"/>
            <ReferenceField source="job_template_id" reference="job_templates" link="show" />
            <DateField showTime={true} source="updated_at"/>
            <DateField showTime={true} source="created_at"/>
        </Tab>
        <Tab label="code">
            <JsonField source="params"/>
        </Tab>
    </TabbedShowLayout>
    </>
}

export const JobPresetShow = (props: ShowProps) => {

    return <Show {...props} actions={<JobPresetShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default JobPresetShow
