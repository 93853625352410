import React from 'react';
import {AutocompleteInput, Create, CreateProps, Edit, EditProps, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';

export const CouponCreate = (props: CreateProps) => (
    <Create {...props}>
      <SimpleForm sx={{maxWidth: '400px'}}>
        <TextInput disabled label="Id" source="id" name="id"/>
        <TextInput label="Code" source="code" name="code"/>
        <NumberInput source="amount" validate={required()} name="amount" helperText="Amount in ByteChips"/>
        <ReferenceInput source="user_id" reference="user_profiles">
          <AutocompleteInput label="User" fullWidth helperText="User that will receive the coupon (leave empty to create a public coupon)"/>
        </ReferenceInput>
        <NumberInput label="Quantity" source="quantity" helperText="Number of times the coupon can be used" validate={required()} defaultValue={1} name="quantity"/>
      </SimpleForm>
    </Create>
);

export const CouponEdit = (props: EditProps) => (
    <Edit mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" name="id"/>
            <NumberInput source="amount" validate={required()} name="amount"/>
          <ReferenceInput source="user_id" reference="users"  name="user_id"><SelectInput /></ReferenceInput>
          <NumberInput source="amount" validate={required()} defaultValue={1} name="quantity"/>
        </SimpleForm>
    </Edit>
);

export * from './ListView'
export * from './ShowView'
