import React, {useEffect, useState} from "react";
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";
import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

type CallbackPageProps = {
  redirectTo?: string
}
const Callback: React.FC<CallbackPageProps> = ({redirectTo}) => {
  const queryParams = new URLSearchParams(location.search);
  const authCode = queryParams.get('code');
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const authCtx = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (authCode && !isLoading) {
      authCtx.exchangeCodeForToken(authCode).then(resp => {
        navigate(redirectTo || '/')
      }).catch(err => {
        setError(err.toString())
      })
    } else if (isLoading) {
      setLoading(false)
    }
  }, [authCode, isLoading])

  if (isLoading) {
    return <></>
  }


  return <>
    {error && <Box>
        <Typography variant="h2">An error occurred</Typography>
        <Typography variant="body1" color="error">{error}</Typography>
    </Box>}
  </>
}

export default Callback
