import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';

const LogField = (props: PublicFieldProps) => {
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const maxWidth = document.getElementById('main-content').clientWidth -60
    const style = {color: '#990303', overflow: 'auto', maxWidth: maxWidth}
    if (!value) {
        return <pre style={style}>No logs available</pre>;
    }
    const cleanValue = value.split('\n').map((s: string) => s.substring(1)).join('\n')

    return <pre style={style}>{cleanValue}</pre>;
}
export default LogField
