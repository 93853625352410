import {Datagrid, DateField, List, ListActions, ShowButton, TextField} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";
import {JobPresetCreate} from "./index";

export const JobPresetList = () => (
    <List actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="category"/>
            <DateField showTime={true} source="created_at"/>
            <DateField showTime={true} source="updated_at"/>
            <ShowButton/>
        </Datagrid>
    </List>
);
