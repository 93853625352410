import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EditProps,
    Labeled,
    NumberField,
    Pagination,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    Show,
    ShowProps,
    SimpleForm,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React, {useEffect, useState} from "react";
import {CommonFilterCondition} from "../../client";
import TaskStateField from "../../components/TaskStateField";
import {JobPresetCreate} from "./index";
import JsonField from "../../components/JsonField";
import JsonSchemaEditor from "../../components/JsonSchemaEditor";

const JobPresetEditLayout = () => {
    const [schema, setSchema] = useState<object>()
    const [schemaId, setSchemaId] = useState<string>(undefined)
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    useEffect(() => {
        if (record.job_template_id) {
            dataProvider.getOne('job_templates', {id: record.job_template_id}).then(async (resp) => {
                if (resp?.data?.schema_id) {
                    setSchemaId(resp?.data?.schema_id)
                    const schema = (await dataProvider.getOne('schemas', {id: resp.data.schema_id}))?.data
                    if (schema.schema) {
                        setSchema(schema.schema)
                    }
                }
            })
        }


    }, [record?.id])

    return <TabbedForm>
        <TabbedForm.Tab label="summary">
            <TextInput disabled name="id" label="Id" source="id"/>
            <Labeled>
                <ReferenceField source="job_template_id" reference="job_templates" link="show" />
            </Labeled>
            <TextInput source="name" name="name" validate={required()}/>
            <TextInput source="category" name="category" validate={required()}/>
            <TextInput source="description"  name="description" validate={required()}/>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="editor">
            <JsonSchemaEditor schema={schema} schemaId={schemaId} source="params"/>
        </TabbedForm.Tab>
    </TabbedForm>
}
export const JobPresetEdit = (props: EditProps) => {
    return <Edit mutationMode="pessimistic" {...props}>
        <JobPresetEditLayout/>
    </Edit>
}


export default JobPresetEdit
