import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';
import {Circle} from "@mui/icons-material";
import {Box, Typography, useTheme} from "@mui/material";

interface ProtobufAny {
    [key: string]: any | any;
    '@type'?: string;
}

export interface IDataSource  {
    dataSourceDescriptor?: string;
    params?: ProtobufAny;
}

const DataSourceField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value:IDataSource = get(record, source)

    if (!value.params) {
        return <Box display="flex" alignItems="center" justifyContent="left"></Box>
    }
    let innerComponent = null
    switch (value.params["@type"]) {
        case 'type.googleapis.com/bytenite.data_source.HttpDataSource':
            innerComponent = <Typography fontWeight="bold" variant="caption">
                <a href={value.params.url}>{value.params.url}</a>
            </Typography>
            break
        default:
            innerComponent = <pre>{JSON.stringify(value.params, null, 2)}</pre>
            break
    }

    return <Box display="flex" alignItems="center" justifyContent="left">
        {innerComponent}
    </Box>
}
export default DataSourceField
