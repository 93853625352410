import React, {Component} from 'react';
import AuthContext, {AuthContextInterface, User} from './context';
import {Box, CircularProgress} from "@mui/material";
import {ApiService} from "../../services/ApiService";
import {AuthApiKey, AuthApiKeyRequest, AuthConnectorCredentialsResponse, AuthSignInResponse, AuthUser} from 'auth/src/client';
import {OAuth2Client, OAuth2Token} from "@badgateway/oauth2-client";

//export const CACHE_TOKEN = "bytenite_oAuthToken";
export const CACHE_TOKEN = "bytenite-auth-token";

export interface AuthProviderProps {
  redirectUri: string;
  idpServerUrl?: string;
  loader?: React.ReactNode;
  onLoad?: () => void;
  apiService?: ApiService;
  oauthClient: OAuth2Client
}


type AuthProviderState<T extends User> = {
  isAuthenticated: boolean;
  isLoading: boolean;
  userInfo: T;
  authUser: any;
  loginUrl?: string;
  signupUrl?: string;
  logoutUrl?: string;
  tokenExpire?: any;
}

interface AuthTokenConfig {
  expirationThreshold?: number;
}

function generateRandomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

class AuthToken {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  private _expiresAt: Date;
  private readonly expirationThreshold: number;
  private defaultConfig: AuthTokenConfig = {
    expirationThreshold: 5
  };

  constructor(accessToken: string, refreshToken: string, expiresAt: number, idToken?: string, config?: AuthTokenConfig) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.idToken = idToken;
    this._expiresAt = new Date(expiresAt * 1000);
    const mergedConfig = {...this.defaultConfig, ...(config || {})}
    this.expirationThreshold = mergedConfig.expirationThreshold ?? 0;
  }

  /**
   * Checks if the access token has expired.
   * @returns {boolean} - True if the access token has expired, false otherwise.
   */
  /**
   * Checks if the access token has expired.
   * @returns {boolean} - True if the access token has expired or will expire within the expiration threshold, false otherwise.
   */
  isExpired(): boolean {
    const currentTime = new Date();
    const expirationThresholdTime = new Date(this._expiresAt.getTime() - this.expirationThreshold);

    return currentTime >= expirationThresholdTime;
  }

  /**
   * Gets the expiration date of the access token.
   * @returns {number} - The expiration timestamp of the access token in seconds.
   */
  get expiresAt(): number {
    return Math.floor(this._expiresAt.getTime() / 1000);
  }

  /**
   * Creates an AuthToken instance from a JSON string.
   * @param jsonString - The JSON string representing the AuthToken object.
   * @param config - Auth token configuration.
   * @returns {AuthToken} - The AuthToken instance created from the JSON string.
   */
  static fromJsonString(jsonString: string, config?: AuthTokenConfig): AuthToken {
    const {accessToken, refreshToken, expiresAt, idToken} = JSON.parse(jsonString);
    return new AuthToken(accessToken, refreshToken, expiresAt, idToken, config);
  }
}

export const tokenExpiredEvent = () => document.dispatchEvent(new CustomEvent('token:expired', {detail: {}}))

class AuthProvider extends Component<React.PropsWithChildren<AuthProviderProps>, AuthProviderState<User>> {
  private idpServerUrl: string;
  private userApi: null;
  private readonly apiService: ApiService;
  private readonly callbackAuthChanged: any;
  private onTokenExpired: (e: CustomEvent) => void;
  private oauthClient: OAuth2Client;

  constructor(props: AuthProviderProps) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isLoading: true,
      userInfo: undefined,//JSON.parse(localStorage.getItem(CACHE_USER)),
      authUser: null,
    }
    this.oauthClient = props.oauthClient
    this.userApi = null;
    this.apiService = this.props.apiService
    this.idpServerUrl = (this.props.idpServerUrl||`${window.location.protocol}//${window.location.host.replace("app.","idp.")}`).replace(/\/$/,'')
  }

  get loginUrl() {
    const currentUrl = window.location.pathname;
    if (currentUrl === this.state.loginUrl) {
      return `${this.state.loginUrl}`
    }
    return `${this.state.loginUrl}?from=${currentUrl || '/'}`
  }
  get authServerUrl() {
    return this.oauthClient.settings.server.replace(/\/$/, '');
  }
  get signUpUrl() {
    return ''
    //return this.signUpUrlWithMail('register')
  }

  get logoutUrl() {
    return this.authServerUrl + `/oauth2/sessions/logout` //?post_logout_redirect_uri=${window.location.origin}/logout
  }

  signOut() {
    localStorage.clear();
    window.location.href = this.logoutUrl
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get('code');
    const tokenStr = localStorage.getItem(CACHE_TOKEN);
    let token: AuthToken = null


    if (tokenStr) {
      try {
        token = AuthToken.fromJsonString(tokenStr) //JSON.parse(tokenStr)
      } catch (e) {
        console.error('cached token error', e)
        localStorage.removeItem(CACHE_TOKEN)
      }
    }
    if (!token) {
      this.clearAuthState()
      return;
    }

    this.oauthClient.getEndpoint('authorizationEndpoint').then((val) => {
      if (!token?.accessToken) {
        this.clearAuthState()
        return
      }
      this.apiService.userApi.authUserInfo({headers: {Authorization: token.accessToken}}).then(
        r => this.setState({
          authUser: r.user,
          isLoading: false,
          isAuthenticated: true,
        })
      ).catch(err => {
        this.clearAuthState()
      })
    })

  }

  componentWillUnmount() {

  }

  clearAuthState() {
    this.setState({
      authUser: null,
      isLoading: false,
      isAuthenticated: false,
      //isAdmin: false,
    })
  }


  async getApiKey(body: AuthApiKeyRequest) {
    const idToken = await this.getToken()
    if (idToken == "") {
      return new Promise((resolve, reject) => {
        reject('Not authenticated')
      })
    }
    const apiService = this.apiService
    return apiService.authApi.authRequestApiKey(body, {headers: {Authorization: idToken}}).then(resp => {
      return resp
    }).catch(err => {
      console.error(err);
      throw err
    })
  }

  async requestSignInCode(email: string): Promise<AuthSignInResponse> {
    return this.apiService.authApi.authRequestSignInCode({email})
  }
  async getLoginUrl(state?: string) {
    state = state || generateRandomString(12)
    const loginUrl = await this.oauthClient.authorizationCode.getAuthorizeUri({redirectUri: this.props.redirectUri, state: state, scope: ['openid', 'offline']})
    return await loginUrl
  }


  async exchangeCodeForToken(authorizationCode: string) {
    const body = {code: authorizationCode, redirectUri: this.props.redirectUri} //grant_type: "authorization_code", AuthorizationCodeRequest
    //const resp = await this.oauthClient.request('tokenEndpoint', body)
    const resp = await this.oauthClient.authorizationCode.getToken(body)
    localStorage.setItem(CACHE_TOKEN, JSON.stringify(resp));
    const user = await this.loadUserInfo()
    window.dispatchEvent(new CustomEvent('auth:token_update', {detail: {user: user, token: resp.accessToken}}))
    return resp
  }

  async setToken(token: OAuth2Token) {
    localStorage.setItem(CACHE_TOKEN, JSON.stringify(token));
    const user = await this.loadUserInfo()
    window.dispatchEvent(new CustomEvent('auth:token_update', {detail: {user: user, token: token.accessToken}}))
  }

  async getToken() {
    return new Promise<string>((resolve, reject) => {
      const cachedToken = localStorage.getItem(CACHE_TOKEN)
      if (cachedToken) {
        try {
          const token = AuthToken.fromJsonString(cachedToken)
          if (token.isExpired() || !token.accessToken) {
            if (!token.refreshToken) {
              reject('No refresh token')
              return
            }
            this.tokenRefresh(token).then(resp => {
              localStorage.setItem(CACHE_TOKEN, JSON.stringify(resp));
              window.dispatchEvent(new CustomEvent('auth:token_update', {detail: {token: resp.accessToken}}))
              // @ts-ignore
              resolve(resp.accessToken)
            }).catch(err => {
              console.error('Removing token from cache', err)
              localStorage.removeItem(CACHE_TOKEN)
              reject(err.toString())
              //TODO: refresh error -> logout
            })
          }
          resolve(token.accessToken)
        } catch (e) {
          console.error('cached token not valid', e)
          localStorage.setItem(CACHE_TOKEN, null);
          reject('Cached token not valid')
        }
      }
      reject('No cached token')
    })
  }

  getCachedToken() {
    const cachedToken = localStorage.getItem(CACHE_TOKEN)
    if (cachedToken) {
      try {
        const token = AuthToken.fromJsonString(cachedToken)
        return token.accessToken
      } catch(e) {
        console.error('Load cached token error', e)
        return null
      }
    }
    return null
  }

  async tokenRefresh(token: AuthToken) {
    return this.oauthClient.refreshToken(token)
  }

  async loadUserInfo(): Promise<AuthUser | null> {
    const token = await this.getToken()
    return new Promise((resolve, reject) => {
      this.apiService.userApi.authUserInfo({headers: {Authorization: token}}).then(
        (r) => {
          this.setState({
            authUser: r.user,
            isLoading: false,
            isAuthenticated: true,
            //isAdmin: r.user.
          }, () => {
            resolve(r.user)
          })

          return r.user
        }
      ).catch(err => {
        this.clearAuthState()
        reject(err)
        return null
      })
    })
  }

  async getApiKeys(): Promise<AuthApiKey[]> {
    const idToken = await this.getToken()
    if (!idToken) {
      return new Promise((resolve, reject) => {
        reject('Not authenticated')
      })
    }
    const apiKeys = await this.apiService.apiKeysApi.authApiKeys({headers: {Authorization: idToken}})
    return apiKeys.apiKeys
  }

  async revokeApiKey(id: string): Promise<any> {
    const idToken = await this.getToken()
    if (idToken == "") {
      return new Promise((resolve, reject) => {
        reject('Not authenticated')
      })
    }
    return await this.apiService.apiKeysApi.authRevokeApiKey(id, {headers: {Authorization: idToken}})
  }

  async getConnectorCredentials(connectorName: string): Promise<AuthConnectorCredentialsResponse> {
    const idToken = await this.getToken()
    if (!idToken) {
      return new Promise((resolve, reject) => {
        reject('Not authenticated')
      })
    }
    return await this.apiService.authApi.authGetConnectorCredentials(connectorName, {headers: {Authorization: idToken}})
  }

  getUserInfo() {
    return this.state.authUser
  }

  getAuthUserInfo() {
    return this.state.authUser
  }

  async refreshUserInfo() {
    return this.loadUserInfo()
  }

  isAuthenticated() {
    return this.state.isAuthenticated
  }

  isAdmin() {
    if (this.state.isLoading || !this.state.userInfo) {
      return false
    }
    const role = this.state.userInfo.role
    return role === 'admin'
  }

  private onTokenUpdate(callback: (e: CustomEvent) => void) {
    window.addEventListener('auth:token_update', callback)
  }

  private removeTokenUpdateListener(callback: (e: CustomEvent) => void) {
    window.removeEventListener('auth:token_update', callback)
  }

  authContext(): AuthContextInterface<User> {
    return {
      getIdpServerUrl: () => this.idpServerUrl,
      getUserInfo: () => this.getUserInfo(),
      getApiKeys: () => this.getApiKeys(),
      revokeApiKey: (id: string) => this.revokeApiKey(id),
      refreshUserInfo: () => this.refreshUserInfo(),
      isAuthenticated: () => this.isAuthenticated(),
      getToken: () => this.getToken(),
      getCachedToken: () => this.getCachedToken(),
      isAdmin: () => this.isAdmin(),
      requestSignInCode: (email: string) => this.requestSignInCode(email),
      getApiKey: (body) => this.getApiKey(body),
      exchangeCodeForToken: (authorizationCode: string) => this.exchangeCodeForToken(authorizationCode),
      setToken: (token: OAuth2Token) => this.setToken(token),
      getLoginUrl: (state?: string) => this.getLoginUrl(state),
      onTokenUpdate: (callback: (e: CustomEvent) => void) => this.onTokenUpdate(callback),
      removeTokenUpdateListener: (callback: (e: CustomEvent) => void) => this.removeTokenUpdateListener(callback),
      signOut: () => this.signOut(),
      getConnectorCredentials: (connectorName: string) => this.getConnectorCredentials(connectorName)
      //signOut: () => this.signOut(),
    }
  }

  render() {
    if (this.state.isLoading) {
      return this.props.loader ? this.props.loader : <Box><CircularProgress/></Box>
    }
    const authCtx = this.authContext()
    return (
      <AuthContext.Provider value={authCtx}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }


}


export const withAuth = <T extends React.ComponentType,>(Component: T) => { //
  return ({ref, ...props}: React.ComponentProps<any>) => (<AuthContext.Consumer>
    {(api: AuthContextInterface<User>) => {
      return <Component ref={ref} auth={api} {...props}/>
    }}
  </AuthContext.Consumer>)
}

export default AuthProvider;
