// React-related imports
import React from "react";
import { Route } from "react-router-dom";

// React Admin
import { Admin, Resource, CustomRoutes } from "react-admin";

// Higher Order Components (HOC)
import ApiProvider from "../hoc/ApiProvider";
import AuthProvider from "../hoc/AuthProvider";
import { withAuth } from "@bytenite/auth/src/hoc/Auth/Provider";

// Auth Context
import { AuthContextInterface, User } from "@bytenite/auth/src/hoc/Auth/context";

// Pages
import LoginPage from "../pages/Login";
import Logout from "../pages/Logout";
import Callback from "../pages/Callback";

// Material UI Icons
import {
  PhoneAndroid,
  Work,
  Apps as AppsIcon,
  DataObject,
  Person,
  LocalOffer,
  Cloud,
  Source,
  AutoStories
} from "@mui/icons-material";

// Pages - Components
import { DevicesList, DeviceShow } from "../pages/Devices";
import { AppList, AppShow } from "../pages/Apps";
import { SchemaList, SchemaShow } from "../pages/Schemas";
import { TaskShow } from "../pages/Tasks";
import { JobCreate, JobEdit, JobShow, JobsList } from "../pages/Jobs";
import { UserProfileEdit, UserProfileList, UserProfileShow } from "../pages/UserProfiles";
import { CouponCreate, CouponList, CouponShow } from "../pages/Coupons";
import { CloudRunnerShow, CloudRunnersList } from "../pages/CloudRunners";
import {UserEdit, UserList, UserShow} from "../pages/Users";
import {Dashboard} from "../pages/Dashboard";
import {JobPresetCreate, JobPresetEdit, JobPresetList, JobPresetShow} from "../pages/JobPresets";
import {JobTemplateList, JobTemplateShow} from "../pages/JobTemplates";


export interface AdminProps {
  loginPage?: any;
  auth: AuthContextInterface<User>
  serverUrl: string
}

//const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");


const App = ({loginPage, auth, serverUrl, ...props}: AdminProps) => {
  const authProvider = AuthProvider(auth)
  const dataProvider = ApiProvider(`${serverUrl}/v1/admin`, auth)

  return <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage} dashboard={Dashboard} {...props}>
    <Resource name="jobs" icon={Work} list={JobsList} show={JobShow} create={JobCreate} edit={JobEdit}/>
    <Resource name="devices" icon={PhoneAndroid} list={DevicesList} show={DeviceShow}/>
    <Resource name="apps" icon={AppsIcon} list={AppList} show={AppShow}/>
    <Resource name="schemas" icon={DataObject} list={SchemaList} show={SchemaShow}/>
    <Resource name="job_presets" icon={AutoStories} list={JobPresetList} show={JobPresetShow} edit={JobPresetEdit} create={JobPresetCreate}/>
    <Resource name="job_templates" icon={Source} list={JobTemplateList} show={JobTemplateShow}/>
    <Resource name="tasks" icon={DataObject} show={TaskShow}/>
    {/*<Resource name="user_profiles" icon={Person} list={UserProfileList} show={UserProfileShow} edit={UserProfileEdit} recordRepresentation="email"/>*/}
    <Resource name="users" icon={Person} list={UserList} show={UserShow} edit={UserEdit} recordRepresentation="email"/>
    <Resource name="coupons" icon={LocalOffer} list={CouponList} show={CouponShow} create={CouponCreate}/>
    <Resource name="cloud_runners" icon={Cloud} list={CloudRunnersList} show={CloudRunnerShow}/>
    <CustomRoutes>
      <Route path="/callback" element={<Callback/>}/>
      <Route path="/profile" element={<Logout/>}/>
    </CustomRoutes>
  </Admin>
};

export default withAuth(App);
