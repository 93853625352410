import React from 'react';
import {
  DateField,
  ReferenceField,
  Show, ShowProps,
  Tab, TabbedShowLayout,
  TextField
} from 'react-admin';

export * from './ListView'

export const CloudRunnerShow = (props: ShowProps) => {
  return <Show {...props}>
    <TabbedShowLayout {...props}>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name"/>
        <TextField source="namespace"/>
        <TextField source="description"/>
        <TextField label="Device id" source="device_id"/>
        <ReferenceField label="Device" source="device_id" reference="devices" link="show" emptyText="(not found)"/>
        <DateField showTime={true} source="created_at"/>
        <TextField source="project" />
        <TextField source="zone_name" label="Zone" />
        <TextField source="tags" />
        <TextField source="machine_type" />
      </Tab>
    </TabbedShowLayout>
  </Show>
}
