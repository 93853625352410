import React from 'react';
import {
    Create,
    CreateProps,
    Edit,
    EditProps,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    DateInput,
    TextField,
    DateField,
} from 'react-admin';
import {Divider, Typography} from "@mui/material";

export const UserCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" name="first_name" required={false}/>
            <TextInput source="last_name"  name="last_name" required={false}/>
            <TextInput source="email"  name="email" type="email" required/>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props: EditProps) => (
    <Edit mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <Typography variant="subtitle1">ID</Typography>
            <TextField source="id" label="ID"/>
            <Typography variant="subtitle1" sx={{mt:1}}>Created at</Typography>
            <DateField source="created_at" label="Created At"/>
            <Typography variant="subtitle1" sx={{mt:1}}>Last Login</Typography>
            <DateField source="last_login" label="Last Login"/>
            <Divider sx={{width: '100%', my:1}}/>
            <TextInput source="display_name" label="Display Name" name="display_name" required/>
            <TextInput source="first_name" label="First Name" name="first_name"/>
            <TextInput source="last_name" label="Last Name" name="last_name"/>
            <SelectInput source="role" label="Role" validate={required()}
                         choices={[{id: 1, name: 'User'}, {id: 16, name: 'Developer'}, {id: 255, name: 'Admin'}]}/>
            <TextInput source="email" label="Email" name="email" type="email" required/>
            <TextInput source="phone_number" label="Phone Number" name="phone_number"/>
            <TextInput source="photo_url" label="Photo URL" name="photo_url"/>
            <TextInput source="provider_id" label="Provider ID" name="provider_id"/>
            <BooleanInput source="disabled" label="Disabled" name="disabled" options={{}}/>
            <BooleanInput source="email_verified" label="Email Verified" name="email_verified" options={{}}/>
            <TextInput source="custom_claims" label="Custom Claims" name="custom_claims" disabled/>
        </SimpleForm>
    </Edit>
);

export * from './ListView'
export * from './ShowView'
