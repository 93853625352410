import {Datagrid, DateField, List, ListActions, ShowButton, TextField, SelectInput, NumberField, ReferenceField, TextInput} from "react-admin";
import React from "react";
import CouponStateField from "../../components/CouponStateField";


const couponStates = [
    { id: 0, name: 'New' },
    { id: 10, name: 'Enabled' },
    { id: 20, name: 'Redeemed' },
    { id: 255, name: 'Canceled' }
];

const couponFilters = [
    <SelectInput label="State" source="state"  name="state" choices={couponStates}/>,
    <TextInput label="Code" source="code"  name="code"/>,
    <TextInput label="User" source="user_id"  name="user_id"/>,
];


export const CouponList = () => (
    <List actions={<ListActions/>} filters={couponFilters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="code"/>
            <CouponStateField source="state"/>
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <ReferenceField label="User" source="user_id" reference="user_profiles"/>
            <NumberField source="amount" />
            <NumberField source="quantity" />
            <DateField source="redeemed_at" />
            <ShowButton/>
        </Datagrid>
    </List>
);
