import {
    BooleanField,
    Button, Datagrid, DateField,
    EditButton, EmailField, List, NumberField, Pagination, PaginationProps, ReferenceManyField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider, useListPaginationContext,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React, {FC, useState} from "react";
import {CommonFilterCondition} from "../../client";
import JsonField from "../../components/JsonField";
import RoleField from "../../components/RoleField";

const CustomPagination = (props: PaginationProps) => {
    const paginationCtx = useListPaginationContext()
    return <Pagination {...props} hasNextPage={paginationCtx.hasNextPage} hasPreviousPage={paginationCtx.hasPreviousPage}/>
}

const UserShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
        {/* Add your custom actions */}
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    const [selectedState, setSelectedState] = useState(null)
    const filters: any = {op:CommonFilterCondition.ARRAYCONTAINS}
    if (selectedState) {
        filters['state'] = selectedState
    }

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id" label="ID" />
            <DateField source="created_at" label="Created At" />
            <DateField source="last_login" label="Last Login" />
            <TextField source="first_name" label="First Name" />
            <TextField source="last_name" label="Last Name" />
            <TextField source="display_name" label="Display Name" />
            <EmailField source="email" label="Email" />
            <RoleField source="role" label="Role"/>
            <TextField source="phone_number" label="Phone Number" />
            <TextField source="photo_url" label="Photo URL" />
            <TextField source="provider_id" label="Provider ID" />
            <BooleanField source="disabled" label="Disabled" />
            <BooleanField source="email_verified" label="Email Verified" />
            <TextField source="custom_claims" label="Custom Claims" />
        </Tab>
    </TabbedShowLayout>
    </>
}

export const UserShow = (props: ShowProps) => {

    return <Show {...props} actions={<UserShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default UserShow
