import React from 'react';
import {Create, CreateProps, Edit, EditProps, ReferenceInput, required, SimpleForm, TextInput} from 'react-admin';

export const JobPresetCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput name="id" label="Id" source="id" validate={required()}/>
            <ReferenceInput  name="job_template_id" source="job_template_id" reference="job_templates"/>
            <TextInput source="name" name="name" validate={required()}/>
            <TextInput source="description"  name="description" multiline={true} validate={required()}/>
        </SimpleForm>
    </Create>
);


export * from './ListView'
export * from './ShowView'
export * from './EditView'