import {
    Datagrid, DateField,
    EditButton, NumberField, Pagination, ReferenceField, ReferenceManyField, Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React from "react";
import {CommonFilterCondition} from "../../client";
import TaskStateField from "../../components/TaskStateField";
import {SchemaCreate} from "./index";
import JsonField from "../../components/JsonField";

const SchemaShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="version"/>
            <TextField source="hash"/>
        </Tab>
        <Tab label="code">
            <JsonField source="schema"/>
        </Tab>
    </TabbedShowLayout>
    </>
}

export const SchemaShow = (props: ShowProps) => {

    return <Show {...props} actions={<SchemaShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default SchemaShow
