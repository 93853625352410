import {PublicFieldProps, useRecordContext} from "react-admin";
import {Box, Typography, useTheme} from "@mui/material";
import get from "lodash/get";
import {Circle} from "@mui/icons-material";
import * as React from "react";

interface StateFieldProps {
    color:string
    label: string
}

const StateField = ({color, label, ...props}: StateFieldProps) => {
    const theme = useTheme();
    return <Box display="flex" alignItems="center" justifyContent="left"><Circle sx={{color:color, width: '0.75em', marginRight: theme.spacing(1)}}/><Typography fontWeight="bold" variant="caption">{label}</Typography></Box>
}
export default StateField
