import {Box, Card, CardContent, CardHeader, Grid, IconButton, Skeleton, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import DateTimeSelectionMenu, {DateInterval, dateTimeMenuOptions} from '../components/DateTimeSelectionMenu'
import {ActionDataProvider, StatsResponse} from "../hoc/ApiProvider";
import {useDataProvider} from "react-admin";
import {subDays, subMonths, subYears} from 'date-fns';
import {Refresh} from "@mui/icons-material";
import {LineChart} from "@mui/x-charts";
import {AxisOptions, Chart} from "react-charts";
import {Series, SeriesFocusStatus} from "react-charts/types/types";

interface StatsCardProps {
  color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
  title: string
  value?: string | number
}
interface ChartPlaceHolderProps {
  height: string | number
}

const StatsCard = ({color, title, value}: StatsCardProps) => {
  const theme = useTheme()
  const style: any = {maxWidth: '320px', minWidth: '200px'}
  if (color) {
    Object.assign(style, {backgroundColor: theme.palette[color].main, color: theme.palette[color].contrastText})
  }
  style.height = '176px'
  if (typeof value === 'undefined') {
    style.borderRadius = theme.shape.borderRadius
    return <Skeleton variant="rectangular" width={'auto'} height={176} style={style}>
      <CardHeader title={title} style={{visibility: 'visible'}}/>
    </Skeleton>
  }
  return <Card style={style}>
    <CardHeader title={title}/>
    <CardContent>
      <Box>
        <Typography align="center" variant="h2">{value}</Typography>
      </Box>
    </CardContent>
  </Card>
}

const ChartPlaceHolder = ({height}: ChartPlaceHolderProps) => <Skeleton height={height} width={'auto'} variant="rounded"><Box style={{visibility: 'visible', height: '100%'}} display="flex" justifyContent="center" alignItems="center"><Typography variant="h5">No data available</Typography></Box></Skeleton>

export const Dashboard = () => {
  const theme = useTheme()
  const [selectedInterval, setSelectedInterval] = useState<DateInterval>('week');
  const [stats, setStats] = useState<StatsResponse>();
  const selectedIntervalLabel = dateTimeMenuOptions.find(o => o.value === selectedInterval)?.label || ''


  const dataProvider: ActionDataProvider = useDataProvider()
  useEffect(() => {
    runQuery(selectedInterval)
  }, [selectedInterval]);

  const runQuery = (selectedInterval: DateInterval) => {
    let dateFrom = new Date()
    let resolution: "DAY" | "WEEK" | "MONTH" = 'DAY'
    switch (selectedInterval) {
      case "today":
        dateFrom = new Date(); // today
        break;
      case "week":
        dateFrom = subDays(new Date(), 7); // last week
        break;
      case "month":
        dateFrom = subMonths(new Date(), 1); // last month
        break;
      case "year":
        dateFrom = subYears(new Date(), 1); // last year
        resolution = "MONTH"
        break;
    }
    dataProvider.getStats(dateFrom, undefined, resolution).then(resp => {
      console.log('STATS', resp.tables)
      setStats(resp)
    })


  }

  const usersChartData = React.useMemo(() => {
    return stats?.tables.new_users?.rows?.length ? [
      {
        label: 'New users',
        data: stats.tables.new_users.rows.map(row => ({date: new Date(row.date), value: row.count || 0})),
        color: theme.palette.info.main,
      },
    ] : null
  }, [stats, selectedInterval])

  const jobsChartData = React.useMemo(() => {
    return stats?.tables.jobs?.rows?.length ? [
      {
        label: 'Created',
        data: stats.tables.jobs.rows.map(row => ({date: new Date(row.date), value: row.created || 0})),
        color: theme.palette.info.main,
      },
      {
        label: 'Completed',
        data: stats.tables.jobs.rows.map(row => ({date: new Date(row.date), value: row.completed || 0})),
        color: theme.palette.success.main,
      },
      {
        label: 'Failed',
        data: stats.tables.jobs.rows.map(row => ({date: new Date(row.date), value: row.failed || 0})),
        color: theme.palette.error.main,
      },
    ] : null
  }, [stats, selectedInterval])

  const paymentsChartData = React.useMemo(() => {
    return stats?.tables.jobs?.rows?.length ? [
      {
        label: 'ByteChips',
        data: stats.tables.jobs.rows.map(row => ({date: new Date(row.date), value: row.payment_amount || 0})),
        color: theme.palette.info.main,
      },
    ] : null
  }, [stats, selectedInterval])

  const primaryAxis = React.useMemo(
    (): AxisOptions<{ date: Date, value: number  }> => ({
      getValue: datum => datum.date,
    }),
    []
  )

  const secondaryAxesBars = React.useMemo(
    (): AxisOptions<{ date: Date, value: number  }>[] => [
      {
        getValue: datum => datum.value,
        elementType: 'bar'
      },
    ],
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<{ date: Date, value: number  }>[] => [
      {
        getValue: datum => datum.value,
        elementType: 'area'
      },
    ],
    []
  )
  const getSeriesStyle = React.useCallback(
    (series: Series<any>, status: SeriesFocusStatus) => {
    return {
      color: series.originalSeries.color,
    }
  },
    []
  )

  const valueFormatter = (date: Date) => date.toDateString()

  return <Box my={2}>
    <Grid spacing={2} container>
      <Grid xs={12} md={12} item>
        <Box display="flex" flexDirection="row">
          <Typography variant="h5">Dashboard</Typography>
          <Box flexGrow={1}></Box>
          <IconButton color="primary" sx={{mr: 1}} onClick={() => runQuery(selectedInterval)}><Refresh/></IconButton>
          <DateTimeSelectionMenu onChange={setSelectedInterval} defaultValue={selectedInterval}/>
        </Box>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="Active users" value={stats?.values?.active_users} color="success"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="New users" value={stats?.values?.new_users} color="info"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="New devices" value={stats?.values?.new_devices} color="info"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="Active devices" value={stats?.values?.active_devices} color="info"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="New jobs" value={stats?.values?.new_jobs} color="info"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="Completed jobs" value={stats?.values?.completed_jobs} color="success"/>
      </Grid>
      <Grid xs={6} md={3} item>
        <StatsCard title="Failed jobs" value={stats?.values?.failed_jobs} color="error"/>
      </Grid>
      <Grid xs={12} md={12} item>
        <Card>
          <CardHeader title="New users"/>
          <CardContent>
            <Box sx={{minHeight: '450px'}} margin={2}>
              {usersChartData ? <Chart
                options={{
                  data: usersChartData,
                  primaryAxis,
                  secondaryAxes: secondaryAxesBars,
                  getSeriesStyle
                }}
              /> : <ChartPlaceHolder height={400} />}
            </Box>

          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={12} item>
        <Card>
          <CardHeader title="Jobs"/>
          <CardContent>
            <CardContent>
              <Box sx={{minHeight: '400px'}} margin={2}>
              {jobsChartData ?
                  <Chart
                      options={{
                        data: jobsChartData,
                        primaryAxis,
                        secondaryAxes,
                        getSeriesStyle
                      }}
                  /> : <ChartPlaceHolder height={400} />
              }
              </Box>
            </CardContent>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={12} item>
        <Card>
          <CardHeader title="Payments"/>
          <CardContent>
            <Box sx={{minHeight: '400px'}} margin={2}>
              {paymentsChartData ?
                  <Chart
                      options={{
                        data: paymentsChartData,
                        primaryAxis,
                        secondaryAxes,
                        getSeriesStyle
                      }}
                  /> : <ChartPlaceHolder height={400} />
              }
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  </Box>
}

export default Dashboard
