import {
    Datagrid, DateField,
    EditButton, NumberField, Pagination, ReferenceField, ReferenceManyField, Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import React from "react";
import {CommonFilterCondition} from "../../client";
import TaskStateField from "../../components/TaskStateField";
import {TaskCreate} from "./index";
import JsonField from "../../components/JsonField";
import LogField from "../../components/LogField";

const TaskShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TaskStateField source="state"/>
            <TextField source="id"/>
            <NumberField source="chunk_number"/>
            <NumberField source="chunk_id"/>
            <DateField source="created_at" showTime={true}/>
            <DateField source="started_at" showTime={true}/>
            <DateField source="completed_at" showTime={true}/>

            <TextField source="assigned_to"/>
        </Tab>
        <Tab label="params">
            <JsonField source="app_params"/>
        </Tab>
        <Tab label="metadata">
            <JsonField source="metadata"/>
        </Tab>
        <Tab label="log">
            <JsonField source="error"/>
            <LogField source="runner_log"/>
        </Tab>
    </TabbedShowLayout>
    </>
}

export const TaskShow = (props: ShowProps) => {

    return <Show {...props} actions={<TaskShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default TaskShow
