import {
  Datagrid,
  DateField,
  ExportButton,
  List,
  ListActionsProps,
  ShowButton,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify
} from "react-admin";
import React, {useState} from "react";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import Button from '@mui/material/Button';
import {Speed} from "@mui/icons-material";
import {ModalProvider, useModals} from "@bytenite/components/src/hoc/Modals/Provider";
import {Box, Slider, Stack} from "@mui/material";
import {FormChangeHandler, FormRenderer} from "@bytenite/components/src/hoc/Modals/context";
import Typography from "@mui/material/Typography";


type ScaleActionParams = {
  minInstances?: number;
  maxInstances?: number
  maxAllowedInstances?: number
}

type ScaleActionFormProps = {
  changeHandler: FormChangeHandler<ScaleActionParams>
  defaultValues: ScaleActionParams
}

type CloudRunnerConfig = {
  image: string;
  machine_type: string;
  max_allowed_instances: number;
  max_instances: number;
  max_parallel_ops: number;
  min_instances: number;
  namespace: string;
  project: string;
  region: string;
  service_account_path: string;
  zones: string[];
};

const ScaleActionForm: React.FC<ScaleActionFormProps> = ({changeHandler, defaultValues, ...props}) => {
  const [formData, setFormData] = useState<ScaleActionParams>({...defaultValues})
  const valuetext = (value: number) => {
    return `${value}`;
  }

  return <Box py={3}>
    <Typography variant="subtitle1">Min/Max instances</Typography>
    <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
      <Typography variant="subtitle1" sx={{minWidth: '32px'}}>{formData.minInstances}</Typography>
      <Slider
        getAriaLabel={() => 'Min/Max instances'}
        defaultValue={[defaultValues.minInstances, defaultValues.maxInstances]}
        onChange={(e: Event, newValue: number[]) => {
          changeHandler('minInstances', newValue[0])
          const formValues = changeHandler('maxInstances', newValue[1])
          setFormData({...formData, ...formValues})
        }}
        min={0}
        max={defaultValues.maxAllowedInstances || 1}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
      />
      <Typography variant="subtitle1" sx={{minWidth: '32px'}} align="right">{formData.maxInstances}</Typography>
    </Stack>
    <Typography variant="caption">Select the number of instances to deploy</Typography>
  </Box>
}

const CloudRunnersActions = ({filters, ...props}: ListActionsProps) => {
  const dataProvider: ActionDataProvider = useDataProvider()
  const notify = useNotify();
  const modals = useModals()
  const scaleAction = () => {

    const form: FormRenderer<ScaleActionParams> = (changeHandler, defaultValues) => {
      return <ScaleActionForm changeHandler={changeHandler} defaultValues={defaultValues}/>
    }

    dataProvider.readAction<CloudRunnerConfig>('cloud_runners', 'config').then((runnersConfig) => {
      const defaultValues: ScaleActionParams = {
        maxAllowedInstances: runnersConfig.max_allowed_instances,
        maxInstances: runnersConfig.max_instances,
        minInstances: runnersConfig.min_instances,
      }

      modals.Form<ScaleActionParams>("Scale cloud runners", form, {defaultValues, size: 'sm', disableTypography: true}).then(({ok, data}) => {
        const formData = {maxInstances: data.maxInstances, minInstances: data.minInstances}
        dataProvider.bulkAction('cloud_runners', 'scale_instances', formData).then(() => {
          //TODO: toast
          notify(`Scaling parameters updated successfully`, {type: 'success'});
        }).catch(err => {
          notify(`Scaling error: ${err.message}`, {type: 'error'});
        })
      }).catch(() => {
        //Do not notify: rejection for modal closed/canceled
      })
    }).catch(err => {
      notify(`Scaling error: ${err.message}`, {type: 'error'});
    })
  }


  // {cloneElement(filters, {context: 'button'})}
  return <TopToolbar>
    <ExportButton/>
    <Button size="small" onClick={scaleAction}>
      <Speed/>
      Scale
    </Button>
  </TopToolbar>
};

export const CloudRunnersList = () => (
  <ModalProvider>
    <List actions={<CloudRunnersActions/>} filters={[]}>
      <Datagrid>
        <TextField source="name"/>
        <TextField source="namespace"/>
        <TextField source="device_id"/>
        <DateField showTime={true} source="created_at"/>
        <TextField source="project"/>
        <TextField source="zone_name" label="Zone"/>
        <TextField source="tags"/>
        <ShowButton/>
      </Datagrid>
    </List>
  </ModalProvider>
);

