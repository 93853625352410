import {PublicFieldProps, useRecordContext} from "react-admin";
import {Box, Typography, useTheme} from "@mui/material";
import get from "lodash/get";
import {Circle} from "@mui/icons-material";
import * as React from "react";
import {Theme} from "@mui/material/styles";
import StateField from "./StateField";


export enum Role {
    UNKNOWN = 0,
    USER = 1,
    DEVELOPER = 16,
    ADMIN = 255
}

export const getLabelColor = (value: Role, theme: Theme) => {
    switch (value) {
        case Role.UNKNOWN:
            return  theme.palette.error.light
        case Role.USER:
            return  theme.palette.info.main
        case Role.DEVELOPER:
            return  theme.palette.info.dark
        case Role.ADMIN:
            return  theme.palette.warning.main
        default:
            return theme.palette.grey.A400
    }
}

const RoleField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const label = (Role[value]||'').toLowerCase()
    return <StateField label={label} color={getLabelColor(value, theme)}/>
}
export default RoleField