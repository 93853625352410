import {PublicFieldProps, useRecordContext} from "react-admin";
import {Box, Button, Menu, MenuItem, Typography, useTheme} from "@mui/material";
import get from "lodash/get";
import {Circle, Edit, FileCopy, KeyboardArrowDown} from "@mui/icons-material";
import * as React from "react";
import {Divider} from "@mui/material";
import {useState} from "react";


export type DateInterval = 'today' | 'week' | 'month' | 'year'
export const dateTimeMenuOptions: {value: DateInterval, label: string}[] = [
    {value: 'today', label: 'Today'},
    {value: 'week', label: 'This week'},
    {value: 'month', label: 'This month'},
    {value: 'year', label: 'This year'},
]

interface DateTimeSelectionMenuProps {
    defaultValue: DateInterval
    onChange: (value: DateInterval) => void
}

const DateTimeSelectionMenu = ({defaultValue, onChange, ...props}: DateTimeSelectionMenuProps) => {
    const theme = useTheme();


    const defaultLabel = dateTimeMenuOptions.find(o => o.value === defaultValue)?.label || 'This week'

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value: DateInterval) => {
        const label = dateTimeMenuOptions.find(o => o.value === value)?.label || value
        setAnchorEl(null);
        onChange(value)
    };

    return <Box>
        <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown/>}
        >
            {defaultLabel}
        </Button>
        <Menu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {dateTimeMenuOptions.map(menuOption =>
                <MenuItem key={menuOption.value} onClick={() => handleClose(menuOption.value)} disableRipple>
                    {menuOption.label}
                </MenuItem>
            )}
        </Menu>
    </Box>
}
export default DateTimeSelectionMenu
