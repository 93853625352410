import {Datagrid, DateField, List, ListActions, ShowButton, TextField} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";
import {SchemaCreate} from "./index";

export const SchemaList = () => (
    <List actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="version"/>
            <DateField showTime={true} source="created_at"/>
            <ShowButton/>
        </Datagrid>
    </List>
);
