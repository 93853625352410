import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';
import {Circle} from "@mui/icons-material";
import {Box, Chip, Typography, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {OverridableStringUnion} from "@mui/types";
import {ChipPropsColorOverrides} from "@mui/material/Chip/Chip";
import {useState} from "react";
import {getEnumKeys} from "../utils";

interface StatesMap {
    id: string;
    label: string;
    color: string;
}

export interface TaskStateChipsProps {
    onSelect: (v: string|number) => void;
    onDelete: (v: string|number) => void;
    selectedState?: string;
}

export enum TaskState {
    UNKNOWN = 0,
    SCHEDULED = 0x02,
    QUEUED = 0x04,
    ASSIGNED = 0x08,
    RECEIVED = 0x0C,
    RUNNING = 0x1C,
    PAUSED = 0x3C,
    STOPPED = 0x2E,
    COMPLETED = 0x4C,
    FAILED = 0x01,
    STALE = 0x11,
}

export const getTaskStateThemeColor = (taskState: TaskState|string) => {
    let color: OverridableStringUnion<'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'> = 'default'
    switch (taskState) {
        case TaskState.SCHEDULED:
            color = 'default'
            break
        case TaskState.QUEUED:
            color = 'primary'
            break
        case TaskState.ASSIGNED:
            color = 'primary'
            break
        case TaskState.RECEIVED:
        case TaskState.RUNNING:
            color = 'info'
            break
        case TaskState.COMPLETED:
            color = 'success'
            break
        case TaskState.FAILED:
        case TaskState.STOPPED:
            color = 'error'
            break
        case TaskState.PAUSED:
        case TaskState.STALE:
            color = 'warning'
            break
    }
    return color
}
export const getTaskStateColor: (theme: Theme, taskState: TaskState) => string = (theme: Theme, taskState: TaskState) => {
    let color = theme.palette.grey.A400
    switch (taskState) {
        case TaskState.SCHEDULED:
            color = theme.palette.grey.A400
            break
        case TaskState.QUEUED:
            color = theme.palette.info.light
            break
        case TaskState.ASSIGNED:
            color = theme.palette.info.main
            break
        case TaskState.RECEIVED:
        case TaskState.RUNNING:
            color = theme.palette.success.light
            break
        case TaskState.COMPLETED:
            color = theme.palette.success.main
            break
        case TaskState.FAILED:
        case TaskState.STOPPED:
            color = theme.palette.error.main
            break
        case TaskState.PAUSED:
        case TaskState.STALE:
            color = theme.palette.warning.main
            break
    }
    return color
}

const TaskStateField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const label = (TaskState[value]||'').toLowerCase()
    const color = getTaskStateColor(theme, value)
    return <Box display="flex" alignItems="center" justifyContent="left"><Circle sx={{color:color, width: '0.75em', marginRight: theme.spacing(1)}}/><Typography fontWeight="bold" variant="caption">{label}</Typography></Box>
}


export const TaskStateChips = ({onSelect, onDelete, selectedState, ...props}: TaskStateChipsProps) => {

    const taskStates = getEnumKeys(TaskState)
    const statesMap: StatesMap[] = taskStates.map(s => ({id: TaskState[s], label: s, color: getTaskStateThemeColor(TaskState[s])}))

    return <Box display="flex" justifyContent="space-evenly">
        {statesMap.map(s => <Chip key={`taskStates-${s.id}`} color={s.color} label={s.label} variant={selectedState === s.id ? 'filled': 'outlined'} onDelete={selectedState === s.id ? () => onDelete(s.id) : null} onClick={selectedState === s.id ? null : () =>onSelect(s.id)}/>)}
    </Box>
}

export default TaskStateField
