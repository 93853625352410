import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useRecordContext } from 'react-admin';
import {Circle} from "@mui/icons-material";
import {Box, Typography, useTheme} from "@mui/material";
import StateField from "./StateField";
import {Theme} from "@mui/material/styles";

const couponStates = [
    { id: 0, name: 'New' },
    { id: 10, name: 'Enabled' },
    { id: 20, name: 'Redeemed' },
    { id: 255, name: 'Canceled' }
];

export enum CouponState {
    NEW = 0,
    ENABLED = 10,
    REDEEMED = 20,
    CANCELED = 255,
}



export const getLabelColor = (value: CouponState, theme: Theme) => {
    switch (value) {
        case CouponState.NEW:
            return  theme.palette.info.light
        case CouponState.ENABLED:
            return  theme.palette.primary.main
        case CouponState.REDEEMED:
            return  theme.palette.success.light
        case CouponState.CANCELED:
            return  theme.palette.error.main
        default:
            return theme.palette.grey.A400
    }
}

const CouponStateField = (props: PublicFieldProps) => {
    const theme = useTheme();
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const label = (CouponState[value]||'').toLowerCase()
    return <StateField label={label} color={getLabelColor(value, theme)}/>
}
export default CouponStateField
