import React from 'react';
import {
    Create,
    CreateProps,
    Edit,
    EditProps,
    required,
    SimpleForm,
    TextInput,
} from "react-admin";

export * from './ListView'
export * from './ShowView'

export const JobCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
        </SimpleForm>
    </Create>
);

export const JobEdit = (props: EditProps) => (
    <Edit mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id"/>
            <TextInput source="name" validate={required()}/>
            <TextInput source="owner" validate={required()}/>
        </SimpleForm>
    </Edit>
);