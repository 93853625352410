import {Datagrid, DateField, List, ListActions, ShowButton, TextField, SelectInput, BooleanField} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";
import {UserCreate} from "./index";
import RoleField from "../../components/RoleField";

const deviceFilters = [
    <SelectInput label="Role" source="role" name="role" choices={[ {id: 1, name: 'User'}, {id: 255, name: 'Admin'}]}/>,
];

export const UserList = () => (
    <List actions={<ListActions/>} filters={deviceFilters}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="display_name"/>
            <TextField source="email"/>
            <RoleField source="role"/>
            <DateField showTime={true} source="created_at"/>
            <DateField showTime={true} source="last_login"/>
            <BooleanField source="mfa_enabled"/>
            <ShowButton/>
        </Datagrid>
    </List>
);
