import React from "react";
import {stringify} from 'query-string';
import {DataProvider, fetchUtils, GetOneParams} from 'react-admin';
import {AdminReadActionResponse, AdminStatsResponse, CommonFilterCondition, CommonGenericResponse, Configuration, DataApi, InfoApi, ProtobufAny, StatsApi} from "../../client";
import {AuthContextInterface, User} from "@bytenite/auth/src/hoc/Auth/context";
import {GetOneResult, RaRecord} from "ra-core/dist/cjs/types";
import {useNotify} from "ra-core";
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */


export interface ActionDataProvider<ResourceType extends string = string> extends DataProvider<ResourceType> {
    action: <RecordType extends RaRecord = any>(resource: ResourceType, action: string, id: string) => Promise<GetOneResult<RecordType>>;
    bulkAction: (resource: ResourceType, action: string, data: any) => Promise<CommonGenericResponse>;
    readAction: <T>(resource: string, action: string) => Promise<T>;
    getStats: (dateFrom?: Date, dateTo?: Date,  resolution?: "DAY" | "WEEK" | "MONTH") =>  Promise<StatsResponse>;
}

export default (
    apiUrl: string,
    auth: AuthContextInterface<User>,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): ActionDataProvider => {
    const apiConfig = {basePath: `${apiUrl}` || `${window.location.protocol}//${window.location.host}`}
    const config = new Configuration({
        ...apiConfig,
        get apiKey() {
            return auth.getCachedToken()
        },
    })

    let infoApi = new InfoApi(config)
    let dataApi = new DataApi(config)
    let statsApi = new StatsApi(config)
    const tokenUpdateCallback = () => {
        dataApi = new DataApi({
            ...apiConfig,
            get apiKey() {
                return auth.getCachedToken()
            }
        })
        infoApi = new InfoApi({
            ...apiConfig,
            get apiKey() {
                return auth.getCachedToken()
            }
        })
    }

    auth.onTokenUpdate(tokenUpdateCallback)

    const notify = useNotify()

    return {
        getList: (resource, params) => {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;

            const offset = (page - 1) * perPage;

            const filterFields = Object.keys(params.filter)
            //filters: [{field: params.target, condition: params.filter?.op||CommonFilterCondition.EQ, value: {'@type': 'google.protobuf.Value', value: params.id}}]
            const filters = filterFields.map((field) => ({
                field: field,
                condition: params.filter?.op || CommonFilterCondition.EQ,
                value: {'@type': 'google.protobuf.Value', value: params.filter[field]}
            }))

            return dataApi.adminFilter(resource, {
                orderBy: [field, order].join(' '),
                filters: filters,
                pagination: {
                    limit: perPage,
                    offset: offset,
                }
            }, {}).then(resp => {
                return {
                    data: resp.data,
                    pageInfo: {
                        hasPreviousPage: resp.pagination.hasPrevious,
                        hasNextPage: resp.pagination.hasNext,
                    },
                    //total: resp.pagination.total
                }
            })
        },

        getOne: (resource, params) => {
            return dataApi.adminGetById(resource, params.id, {}).then(resp => {
                return {
                    data: resp.data
                }
            })
        },

        action: (resource: string, action: string, id: string) => {
            return dataApi.adminAction(resource, action, id, {}).then(resp => {
                return {
                    data: resp.data
                }
            })
        },

        bulkAction(resource: string, action: string, data: any): Promise<CommonGenericResponse> {
            return dataApi.adminBulkAction(resource, action, data).then(resp => {
                return resp
            })
        },

        readAction<T>(resource: string, action: string): Promise<T> {
            return dataApi.adminReadAction(resource, action).then((resp) => {
                return  resp.data as T;
            })
        },

        getStats(dateFrom?: Date, dateTo?: Date,  resolution: "DAY" | "WEEK" | "MONTH" = "DAY"):  Promise<StatsResponse> {
            return statsApi.adminStats(dateFrom, dateTo, resolution).then((resp) => {
                const mappedTables: {[key: string]: StatsTable} = {}
                Object.keys(resp.tables).forEach(tableKey => {
                    const table: StatsTable = {rows: []}
                    table.rows = resp.tables[tableKey].rows.map(row => {
                        const ret:  { [key: string]: any } = {}
                        for (let columnName in row.values) {
                            ret[columnName] = row.values[columnName].value
                        }
                        return ret
                    })
                    mappedTables[tableKey] = table
                })
                return  {
                    values: resp.values,
                    tables: mappedTables
                }
            })
        },

        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            //const { page, perPage } = params.pagination;
            //const { field, order } = params.sort;

            //const offset = (page -1 ) * perPage;

            return dataApi.adminFilter(resource, {
                filters: [{
                    field: 'id',
                    condition: CommonFilterCondition.IN,
                    value: {'@type': 'google.protobuf.Value', value: params.ids}
                }]
                /*orderBy: [field, order].join(' '),
                pagination: {
                    limit: perPage,
                    offset: offset,
                }*/
            }, {}).then(resp => {
                return {
                    data: resp.data,
                    total: resp.pagination.total
                }
            })
        },

        getManyReference: (resource, params) => {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;

            const offset = (page - 1) * perPage;

            const {op, ...filter} = params.filter

            const filterFields = Object.keys(filter)

            const filters = filterFields.filter(field => field !== params.target).map((field) => ({
                field: field,
                condition: CommonFilterCondition.EQ,
                value: {'@type': 'google.protobuf.Value', value: filter[field]}
            }))

            return dataApi.adminFilter(resource, {
                orderBy: [field, order].join(' '),
                pagination: {
                    limit: perPage,
                    offset: offset,
                },
                filters: [{
                    field: params.target,
                    condition: op || CommonFilterCondition.EQ,
                    value: {'@type': 'google.protobuf.Value', value: params.id}
                }, ...filters]
            }, {}).then(resp => {
                return {
                    data: resp.data,
                    pageInfo: {
                        hasNextPage: resp.pagination.hasNext,
                        hasPreviousPage: resp.pagination.hasPrevious,
                    }
                }
            })
        },

        create: (resource, params) => {
            return dataApi.adminCreate(resource, {...params.data}, {}).then(resp => {
                return {
                    data: resp.data
                }
            })
        },

        update: (resource, params) => {
            const body: any = {}
            for (let key in params.data) {
                const prevVal = params.previousData[key]
                const val = params.data[key]
                if (JSON.stringify(prevVal) !== JSON.stringify(val)) {
                    body[key] = val
                }
            }
            return dataApi.adminUpdate(resource, params.id.toString(), body, {}).then(resp => {
                return {
                    data: resp.data
                }
            })
        },

        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({data: responses.map(({json}) => json.id)})),

        delete: (resource, params) =>
            dataApi.adminDelete(resource, params.id.toString(), {}).then(resp => {
                return {
                    data: resp.data
                }
            }),

        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    dataApi.adminDelete(resource, id.toString(), {}).then(resp => {
                        return {
                            data: resp.data
                        }
                    })
                )
            ).then(responses => ({
                data: responses.map(({data}) => data.id),
            }))
    }
};

export interface StatsTable {
    rows:  { [key: string]: any }[]
}

export interface StatsResponse extends Omit<AdminStatsResponse, 'tables'> {
    tables:  { [key: string]: StatsTable }
}
