import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {FieldProps, useRecordContext } from 'react-admin';

const JsonField = (props: FieldProps) => {
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    return <pre>{JSON.stringify(value, null, 2)}</pre>;
}
export default JsonField
