import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
// @ts-ignore
import { JsonEditor } from 'jsoneditor-react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import {FieldProps, useInput, useRecordContext} from 'react-admin';
import {Alert, Box, Button, Grid, ThemeProvider} from "@mui/material";
// @ts-ignore
import {uiSchema as uiSchemaVideoTranscoding} from "@bytenite/components/src/schemas/videoTranscoding";
// @ts-ignore
import JsonSchemaForm from "@bytenite/components/src/components/JsonSchemaForm/JsonSchemaForm";
import byteniteTheme from "@bytenite/components/src/themes/bytenite-theme/byteniteTheme";
import {Code, CodeOff} from "@mui/icons-material";
import {useState} from "react";

const JsonSchemaEditor = ({schema, schemaId, source, ...props}: FieldProps & { schema?: object, schemaId?: string }) => {
    const [jsonMode, setJsonMode] = useState(false)
    const { id, field, fieldState } = useInput({ source });
    const record = useRecordContext(props);
    const handleSetJsonMode = () => {
        setJsonMode(!jsonMode)
    }
    if (!schema) {
        return <Alert color="error">Schema not set</Alert>
    }


    const uiSchema = schemaId === 'video-transcoding' ? uiSchemaVideoTranscoding: undefined
    return <ThemeProvider theme={byteniteTheme}>
        <Button onClick={handleSetJsonMode} startIcon={jsonMode? <CodeOff/> : <Code/>}>{jsonMode ? 'Switch to editor' : 'Switch to JSON'}</Button>
        {jsonMode ? <Grid container>
            <Grid item xs={6} p={1} sx={{minHeight: '400px'}} className="fillHeight">
                <JsonEditor
                    value={field.value}
                    onChange={(value: object) => field.onChange(value)}
                />
            </Grid>
            <Grid item xs={6} p={1} className="fillHeight">
                <JsonEditor
                    value={field.value}
                    mode="code"
                    theme="ace/theme/github"
                    ace={ace}
                    onChange={(value: object) => field.onChange(value)}
                />
            </Grid>
        </Grid> : <JsonSchemaForm schema={schema}
                                            style={{width: '100%'}}
                                            uiSchema={uiSchema}
                                            data={field.value}
                                            onChange={({formData}: {formData: object}) => field.onChange(formData)}
                                            noSubmit={true}
                                            liveValidate={false}/>}
    </ThemeProvider>
}
export default JsonSchemaEditor
