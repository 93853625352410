import {Datagrid, DateField, List, ListActions, ReferenceField, ShowButton, TextField} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";
import {JobTemplateCreate} from "./index";

export const JobTemplateList = () => (
    <List actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="app_id" reference="apps"  link="show" />
            <ReferenceField source="schema_id" reference="schemas"  link="show" />
            <DateField showTime={true} source="created_at"/>
            <DateField showTime={true} source="updated_at"/>
            <ShowButton/>
        </Datagrid>
    </List>
);
