import React, {useEffect, useState} from "react";
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";
import {Box, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

type LogoutPageProps = {}
const LogoutPage: React.FC<LogoutPageProps> = ({}) => {
    return <Box>
        <Typography variant="h2">Successfully logged out</Typography>
        <Typography variant="body1"><a href="/">Log in</a> again</Typography>
    </Box>
}

export default LogoutPage
