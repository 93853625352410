import {
        Datagrid,
        DateField,
        List,
        ListActions,
        NumberField,
        SelectInput,
        ShowButton,
        TextField,
        TextInput
} from "react-admin";
import React from "react";
import JobStateField from "../../components/RunnerStateField";
import {TaskCreate} from "./index";
import TaskStateField, {TaskState} from "../../components/TaskStateField";
import {getEnumKeys} from "../../utils";

export const filters = () => {
        const taskStates = getEnumKeys(TaskState)
        return [
                <SelectInput label="State" source="state"  name="state" choices={taskStates.map(s => ({id: taskStates[s], name: s}))}/>,
                <TextInput label="Assigned to" source="assigned_to"  name="assigned_to"/>,
                <TextInput label="Chunk number" source="chunk_number"  name="chunk_number"/>
        ];
}

export const TaskDataGrid = () => (
    <Datagrid>
        <TaskStateField source="state"/>
        <TextField source="id"/>
        <DateField source="created_at" showTime={true}/>
        <DateField source="started_at" showTime={true}/>
        <DateField source="completed_at" showTime={true}/>
        <NumberField source="chunk_number"/>
        <TextField source="assigned_to"/>
        <ShowButton/>
    </Datagrid>
)

export const TaskList = () => (
    <List actions={<ListActions/>} filters={filters()}>
        <TaskDataGrid/>
    </List>
);
