import * as React from "react";
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {PublicFieldProps, useDataProvider, useRecordContext} from 'react-admin';
import {useEffect, useState} from "react";
import {ActionDataProvider} from "../hoc/ApiProvider";


interface HtmlPreviewProps{
    body: string
}

class HtmlPreview extends React.Component<HtmlPreviewProps> {
    rendered?: HTMLIFrameElement

    componentDidMount() {
        this.rendered.contentDocument.body.innerHTML = this.props.body;
    }
    render() {
        return(
            <div>
                <iframe ref={(iframe) => this.rendered = iframe}></iframe>
            </div>
        );
    }
}

export interface OtLogFieldProps extends PublicFieldProps {
    service: string
}

const OtLogField = ({service, ...props}: OtLogFieldProps) => {
    const { source } = props;
    const record = useRecordContext(props);
    const value = get(record, source)
    const maxWidth = document.getElementById('main-content').clientWidth -60
    const style = {color: '#990303', overflow: 'auto', maxWidth: maxWidth}
    const dataProvider: ActionDataProvider = useDataProvider()
    const [pageData, setPageData] = useState()

    if (!value) {
        return <pre style={style}>No logs available</pre>;
    }
    useEffect(() => {

    }, [value])

    return <div>
        <iframe width="100%" height="800px" style={{border: 'none'}} src={`//localhost:8093/search?service=${service}&lookback=24h&uiEmbed=v0&tags={"job_id":"${value}"}`}></iframe>
    </div>;
}
export default OtLogField
