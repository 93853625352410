import {
    Button, Datagrid, DateField,
    EditButton, List, NumberField, Pagination, PaginationProps, ReferenceField, ReferenceManyField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useDataProvider, useListPaginationContext,
    useRecordContext
} from "react-admin";
import {ActionDataProvider} from "../../hoc/ApiProvider";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import React, {FC, useState} from "react";
import {DisconnectButton} from "./DisconnectAction";
import JobStateField from "../../components/RunnerStateField";
import {Box} from "@mui/material";
import {CommonFilterCondition} from "../../client";
import TaskStateField, {TaskStateChips} from "../../components/TaskStateField";
import {TaskDataGrid} from "../Tasks";
import {ListContextProvider, ResourceContextProvider, useReferenceManyFieldController} from "ra-core";
import {ReferenceManyFieldProps} from "ra-ui-materialui/src/field/ReferenceManyField";
import CustomPagination from "../../components/CustomPagination";

const DeviceShowActions = (props: any) => {
    const record = useRecordContext();
    const dataProvider: ActionDataProvider = useDataProvider()

    return <TopToolbar>
        <EditButton/>
        {/* Add your custom actions */}
        <DisconnectButton/>
    </TopToolbar>
};
const ShowLayout = (props: any) => {
    const record = useRecordContext()

    const [selectedState, setSelectedState] = useState(null)
    const filters: any = {op:CommonFilterCondition.ARRAYCONTAINS}
    if (selectedState) {
        filters['state'] = selectedState
    }

    return <>
        <TabbedShowLayout {...props}>
        <Tab label="summary">
            <TextField source="id"/>
            <JobStateField label="State" source="runner_state"/>
            <JobStateField label="Pool state" source="pool_state"/>
            <TextField label="Pool state" source="pool_state"/>
            <TextField label="Pending task" source="pending_task"/>
            <TextField label="Running task" source="running_task"/>
            <TextField source="owner_id"/>
            <ReferenceField label="User profile" source="owner_id" reference="user_profiles"/>
            <TextField source="device_name"/>
            <TextField source="created_at"/>
            <TextField source="updated_at"/>

            <TextField source="device_manufacturer"/>
            <TextField source="device_model"/>

            <TextField source="platforms"/>
            <TextField source="version"/>
        </Tab>
        <Tab label="system">
            <TextField source="manufacturer_device_id"/>
            <TextField source="os_family"/>
            <TextField source="os_name"/>
            <TextField source="os_version"/>
        </Tab>
        <Tab label="hardware">
            <TextField source="gpu_manufacturer"/>
            <TextField source="gpu_model"/>
            <TextField source="gpu_version"/>

            <TextField source="usable_ram"/>
            <TextField source="usable_storage"/>
            <TextField source="clock_speed_core"/>
            <TextField source="cpu_cores"/>
        </Tab>
        <Tab label="configuration">
            <TextField source="public_key"/>
            <TextField source="extra_params"/>
        </Tab>
        <Tab label="tasks">
            <TaskStateChips onSelect={(v) => setSelectedState(v)} onDelete={(v) => setSelectedState(null)} selectedState={selectedState}/>
            <ReferenceManyField filter={filters} sort={{ field: 'chunk_number', order: 'ASC' }} pagination={<Pagination />} label={false} reference="tasks" target="assigned_to">
                <TaskDataGrid/>
                <CustomPagination hasNextPage={true} />
            </ReferenceManyField>
        </Tab>
    </TabbedShowLayout>
    </>
}

export const DeviceShow = (props: ShowProps) => {

    return <Show {...props} actions={<DeviceShowActions/>}>
        <ShowLayout/>
    </Show>
}

export default DeviceShow
