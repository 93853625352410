import {ApiKeysApi, AuthApi, Configuration, InfoApi, UserApi} from '../client';


/*const firebaseConfigTxt = document.querySelector('script[id="firebase-config"]').textContent
if (!firebaseConfigTxt) {
    console.error("No firebase config")
    //throw new Error("No firebase config")
}*/


// const firebaseConfig = JSON.parse(firebaseConfigTxt);

//const firebaseApp = null;
//const firebaseAuth = null;

export class ApiService {
    private readonly _authApi: AuthApi
    private readonly _infoApi: InfoApi
    private readonly _userApi: UserApi
    private readonly _apiKeysApi: ApiKeysApi

    constructor(config?: Configuration) {
        // @ts-ignore
        config = {...(new Configuration({ ...config, fetch:fetch, basePath: `${config.basePath}/auth` || `${window.location.protocol}//${window.location.host}`}))}
        this._authApi = new AuthApi(config)
        this._infoApi = new InfoApi(config)
        this._userApi = new UserApi(config)
        this._apiKeysApi = new ApiKeysApi(config)
    }

    serverInfo() {
        return this._infoApi.authInfo()
    }

    get infoApi() {
        return this._infoApi
    }

    get authApi() {
        return this._authApi
    }

    get userApi() {
        return this._userApi
    }

    get apiKeysApi() {
        return this._apiKeysApi
    }

    idToken() : Promise<string>{
        //TODO: idToken
        return new Promise((resolve) => {resolve("")})
    }

    signOut() : Promise<void>{
        return new Promise((resolve) => {resolve(null)})
        //TODO: signOut
    }

}

export default ApiService
